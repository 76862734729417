@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family:
            Source Sans Pro,
            sans-serif;
    }
}

.container {
    @apply h-full w-full bg-white sm:rounded-lg md:rounded-lg rounded-none xl:p-10 p-6 mx-auto;
}

.title {
    @apply text-3xl pl-4 font-bold text-gray-900;
}

.subtitle {
    @apply text-2xl font-bold text-gray-800;
}

.bouncing-loader {
    display: flex;
    place-items: end;
}

.bouncing-loader > div {
    width: 4px;
    height: 4px;
    margin: 1px 2px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.8s infinite alternate;
}

@keyframes bouncing-loader {
    to {
        opacity: 0.5;
        transform: translateY(-16px);
    }
}

.bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}
