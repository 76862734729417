@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-item {
    @apply flex items-center px-3 py-2  text-gray-200 hover:bg-[rgba(52,53,65,var(--tw-bg-opacity))] transition-colors duration-300 transform rounded-lg;
}

.nav-item-active {
    @apply flex items-center px-3 py-2  transition-colors duration-300 transform rounded-lg text-gray-100 bg-[rgba(52,53,65,var(--tw-bg-opacity))];
}

.sidebar-label {
    @apply px-3 text-xs text-gray-800 uppercase;
}

.sidebar-link {
    @apply flex items-center px-3 py-2 text-gray-600 transition-colors duration-300 transform rounded-lg  hover:bg-gray-100 hover:text-gray-700;
}
